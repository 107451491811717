<template>
  <div>
    <div style="margin-bottom:20px;">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>系統管理</el-breadcrumb-item>
        <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-card shadow="always">
      <div class="title">信息筛选</div>
      <div class="search-content">
        <div class="search-box">
          <div class="search-box__title">登录账号</div>
          <div style="width: 260px;">
            <el-input v-model.trim="account" placeholder="请输入登录账号" maxlength="64" />
          </div>

          <div class="search-box__title" style="margin-left: 30px;">用户昵称</div>
          <div style="width: 260px;">
            <el-input v-model.trim="userName" placeholder="请输入用户昵称" maxlength="100" />
          </div>

        </div>

        <div class="search-btn" @click="search">筛选</div>
      </div>
    </el-card>
    <el-card shadow="always" class="list-container">
      <div class="header">
        <div class="header__title">用户列表</div>
        <div class="header__btn" @click="addUser">新增用户</div>
      </div>
      <div class="content">
        <el-table :data="tableData" :header-cell-style="{'text-align': 'center','font-weight': 'bold',color: '#333'}" :cell-style="{'text-align': 'center',color: '#333'}" style="width: 100%">
          <el-table-column prop="userId" label="用户编号" />
          <el-table-column prop="account" label="登录账号" />
          <el-table-column prop="userName" label="用户昵称">
            <template #default="scope">
              <span>{{scope.row.userName == '' ? '-' : scope.row.userName}}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="deptName" label="所属部门">
            <template #default="scope">
              <span>{{scope.row.deptName ? scope.row.deptName : '-'}}</span>
            </template>
          </el-table-column> -->
          <el-table-column prop="name" label="角色名称">
            <template #default="scope">
              <span>{{scope.row.name ? scope.row.name : '-'}}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="tenantNum" label="关联操作仓数量" /> -->
          <el-table-column prop="createTime" label="注册时间" width="180" />
          <!-- <el-table-column prop="lastLoginTime" label="最后登录时间" width="180">
            <template #default="scope">
              <span>{{scope.row.lastLoginTime ? scope.row.lastLoginTime : '-'}}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="操作">
            <template #default="scope">
              <el-button @click="showDetail(scope.row)" type="text" size="small">详情</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="page">
          <el-pagination v-model:currentPage="currentPage" :page-sizes="[5, 10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { getBackUserList } from "../../../api/sysManagement/user"
export default {
  setup() {
    // const { proxy } = getCurrentInstance();
    const router = useRouter()
    const state = reactive({
      account: '',
      userName: '',
      currentPage: 1,
      total: 0,
      tableData: [],
      pageSize: 10
    })

    onMounted(() => {
      getList()
    })

    const getList = () => {
      let data = {
        account: state.account,
        endTime: "",
        pageNo: state.currentPage,
        pageSize: state.pageSize,
        roleId: "",
        startTime: "",
        userName: state.userName,
        userNum: ""
      }
      getBackUserList(data).then((res) => {
        if (res.resultState === '200') {
          state.tableData = res.data.list;
          state.total = res.data.total;
        }
      }).catch((err) => {

      });
      // proxy.$post('/cts/back/customer/list', data).then((res) => {
      //   if (res.code === '200') {
      //     state.tableData = res.data.list;
      //     state.total = res.data.total;
      //   }
      // }).catch((err) => {

      // });
    }

    const search = () => {
      getList()
    }

    const handleSizeChange = (val) => {
      state.pageSize = val;
      getList();
    }

    const handleCurrentChange = (val) => {
      state.currentPage = val;
      getList();
    }

    const addUser = () => {
      router.push({ path: '/sysManagement/sysUserManagement/add' })
    }

    // const editUser = () => {
    //   router.push({ path: '/userManagement/edit' })
    // }

    const showDetail = (data) => {
      router.push({ path: '/sysManagement/sysUserManagement/detail', query: { id: data.userId } })
    }

    const formatterTime = (row, column) => {
      let time = row.createTime;
      let month = '', date = '', hour = '', minute = '', second = '';
      if (time[1] < 10) {
        month = `0${time[1]}`
      } else {
        month = `${time[1]}`
      }
      if (time[2] < 10) {
        date = `0${time[2]}`
      } else {
        date = `${time[2]}`
      }
      if (time[3] < 10) {
        hour = `0${time[3]}`
      } else {
        hour = `${time[3]}`
      }
      if (time[4] < 10) {
        minute = `0${time[4]}`
      } else {
        minute = `${time[4]}`
      }
      if (time[5] < 10) {
        second = `0${time[5]}`
      } else {
        second = `${time[5]}`
      }
      return `${time[0]}-${month}-${date}  ${hour}:${minute}:${second}`
    }

    return {
      ...toRefs(state),
      search,
      handleSizeChange,
      handleCurrentChange,
      addUser,
     // editUser,
      showDetail,
      formatterTime
    }
  }
}
</script>

<style lang="scss" scoped>
.container-title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 20px 0;
}
.title {
  font-weight: bold;
}
.search-content {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-box {
    display: flex;
    align-items: center;
    width: 800px;
    &__title {
      width: 70px;
    }
  }
  .search-btn {
    background: rgb(56, 162, 138);
    width: 80px;
    padding: 8px 0;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
  }
}
.list-container {
  margin-top: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    &__title {
      font-weight: bold;
    }
    &__btn {
      background: rgb(56, 162, 138);
      width: 110px;
      padding: 8px 0;
      border-radius: 6px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
    }
  }
  .content {
    margin-top: 15px;
    .page {
      float: right;
      margin: 20px;
    }
  }
}
</style>