import { get, post, put, del } from "../../request/http";

export function getBackUserList(params) {
    return post('/cts/back/backUserRole/list', params)
}

export function getAllBackRole() {
    return get('/cts/back/backRoleDO/allBackRole')
}

export function addBackUser(params) {
    return post('/cts/back/backUserRole/add', params)
}

export function getBackUserDetail(params) {
    return post('/cts/back/backUserRole/detail', params)
}

export function updateBackUser(params) {
    return put('/cts/back/backUserRole/update', params)
}

export function delBackUser(params) {
    return del('/cts/back/backUserRole/delete', params)
}

export function resetUserPwd(params) {
    return post('/cts/back/backUserRole/resetPwd', params)
}

export function unlockUser(params) {
    return get('/cts/back/backUserRole/unlockUser', params)
}